import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Stack,
  Textarea,
  Heading,
  Button,
  HStack,
  theme,
  Link,
  Image,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  VStack,
  useToast,
  Container,
  Select as ChakraSelect,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Step } from "./Step";
import { StepContent } from "./StepContent";
import { Steps } from "./Steps";
import { useSteps } from "./useSteps";
import axios from "axios";
import _ from "lodash";
import Select from "react-select";

// const configs = {
//   dashboard: "https://app.walawong.com",
//   api: "https://api.walawong.com",
//   web_connect: "https://connect.walawong.com",
// };

const configs = {
  dashboard: "https://main--walawong-app.netlify.app",
  api: "https://api.stag.walawong.com/v1",
  web_connect: "https://main--wala-connect.netlify.app",
};

type Provider = {
  name: string;
  display_name: string;
  type: string;
  auth_type: string;
};

type Account = {
  id: string;
  provider: Provider;
};

type User = {
  id: string;
  accounts: Account[];
};

type AccountBalance = Account & {
  blances: Balance[];
};

type Balance = {
  amount: string;
  currency: string;
};

type FormValues = {
  appId: string;
  secretKey: string;
  appUserId: string;
  clientUserId: string;
};

type MessageData = {
  method: "walawong_connect";
  params: {
    accountId: string;
  };
};

type Symbol = {
  base: string;
  quote?: string;
};

export const App = () => {
  const {
    register,
    getValues,
    setValue,
    watch,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormValues>({ mode: "all" });

  const toast = useToast();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState("");
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [accountId, setAccountId] = React.useState<string | undefined>(
    undefined
  );
  const [symbol, setSymbol] = React.useState<any | undefined>(undefined);
  const popupRef = React.useRef<Window | null>(null);
  const [symbols, setSymbols] = React.useState<Symbol[]>([]);

  const redirectUrl = window.location.href;

  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const onReset = () => {
    reset();
    resetForm();
    setAccountId("");
    setData("");
  };

  const canGetData = !!watch("appUserId");

  const onMessageEvent = React.useCallback(
    (event: MessageEvent) => {
      console.log("MessageEvent ", event);
      if (event.origin !== configs.web_connect) {
        return;
      }
      console.log("event", event);
      if (event.data) {
        try {
          const data = JSON.parse(event.data);
          console.log("MessageEvent data ", data);
          if (data.method === "walawong_connect") {
            console.log(" setValue accountId ", data.params.accountId);
            setAccountId(data.params.accountId);
            nextStep();
            getAllAccounts();
          }
        } catch (error) {}
      }
      // popupRef.current && popupRef.current.close();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, popupRef.current, nextStep]
  );

  React.useEffect(() => {
    window.addEventListener("message", onMessageEvent, false);
    return () => {
      window.removeEventListener("message", onMessageEvent);
    };
  }, [onMessageEvent]);

  const createUserId = React.useCallback(() => {
    const appId = getValues("appId");
    const secretKey = getValues("secretKey");
    const clientUserId = getValues("clientUserId");
    console.log("appId", appId);
    //console.log("clientUserId", clientUserId);
    setLoading(true);
    axios({
      method: "POST",
      url: `${configs.api}/users`,
      headers: {
        "X-WALAWONG-APP-ID": appId,
        "x-walawong-secret-key": secretKey,
      },
      data: {
        your_user_id: !_.isEmpty(clientUserId) ? clientUserId : undefined,
      },
    })
      .then((result) => {
        //console.log("result", result);
        if (result.status === 200) {
          setValue("appUserId", result.data.id);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          alert(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getValues, setValue]);

  const getAppUserId = React.useCallback(() => {
    const appId = getValues("appId");
    const secretKey = getValues("secretKey");
    const clientUserId = getValues("clientUserId");
    console.log("appId", appId);
    //console.log("clientUserId", clientUserId);
    setLoading(true);
    axios({
      method: "GET",
      url: `${configs.api}/users/${clientUserId}?use_your_user_id=true`,
      headers: {
        "X-WALAWONG-APP-ID": appId,
        "x-walawong-secret-key": secretKey,
      },
    })
      .then((result) => {
        //console.log("result", result);
        if (result.status === 200) {
          setValue("appUserId", result.data.id);
        } else {
          setValue("appUserId", "");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          alert(error.response.data.message);
        }
        setValue("appUserId", "");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getValues, setValue]);

  const getTradingSymbols = React.useCallback(() => {
    const appId = getValues("appId");
    const secretKey = getValues("secretKey");
    const appUserId = getValues("appUserId");
    //console.log("appId", appId);

    console.log("accountId", accountId);
    setLoading(true);
    axios({
      method: "GET",
      url: `${configs.api}/users/${appUserId}/accounts/${accountId}/trading-symbols`,
      headers: {
        "X-WALAWONG-APP-ID": appId,
        "x-walawong-secret-key": secretKey,
      },
    })
      .then((result) => {
        //console.log("result", result);
        if (result.status === 200) {
          setSymbols(result.data);
        } else {
          setSymbols([]);
          setSymbol(undefined);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          alert(error.response.data.message);
        }
        setSymbols([]);
        setSymbol(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accountId, getValues]);

  React.useEffect(() => {
    if (canGetData && !_.isEmpty(accountId)) {
      setSymbol(undefined);
      getTradingSymbols();
    }
  }, [accountId, canGetData, getAppUserId, getTradingSymbols]);

  const connectProvider = React.useCallback(() => {
    const appId = getValues("appId");
    const appUserId = getValues("appUserId");
    const connectUrl = `${configs.web_connect}/?appId=${appId}&userId=${appUserId}&origin=${redirectUrl}&redirectUrl=${redirectUrl}`;
    const width = 432;
    const height = 768;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    console.log("Open popup", connectUrl);
    if (popupRef.current === null || popupRef.current.closed) {
      popupRef.current = window.open(
        connectUrl,
        "walawong",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, 
        scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
        height=${height}, top=${top}, left=${left}`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, popupRef.current]);

  const getBalances = React.useCallback(() => {
    const appId = getValues("appId");
    const secretKey = getValues("secretKey");
    const appUserId = getValues("appUserId");
    setLoading(true);
    axios({
      method: "GET",
      url: `${configs.api}/users/${appUserId}/accounts/${accountId}/balances`,
      headers: {
        "X-WALAWONG-APP-ID": appId,
        "x-walawong-secret-key": secretKey,
      },
    })
      .then((result) => {
        console.log("getBalances result", result);
        setData(JSON.stringify(result.data, undefined, 4));
      })
      .catch((err) => {
        //setData(err.);
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, getValues]);

  const getTransaction = React.useCallback(() => {
    const appId = getValues("appId");
    const secretKey = getValues("secretKey");
    const appUserId = getValues("appUserId");
    setLoading(true);
    axios({
      method: "GET",
      url: `${
        configs.api
      }/users/${appUserId}/accounts/${accountId}/transactions${
        _.isEmpty(symbol) ? "" : `?symbol=${symbol.value}`
      }`,
      headers: {
        "X-WALAWONG-APP-ID": appId,
        "x-walawong-secret-key": secretKey,
      },
    })
      .then((result) => {
        setData(JSON.stringify(result.data, undefined, 4));
        // if (result.data)
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accountId, getValues, symbol]);

  const getAllAccounts = React.useCallback(() => {
    const appId = getValues("appId");
    const secretKey = getValues("secretKey");
    const appUserId = getValues("appUserId");
    //const accountId = getValues("accountId");
    setLoading(true);
    axios({
      method: "GET",
      url: `${configs.api}/users/${appUserId}`,
      headers: {
        "X-WALAWONG-APP-ID": appId,
        "x-walawong-secret-key": secretKey,
      },
    })
      .then((result) => {
        // setData(JSON.stringify(result.data, undefined, 4));
        if (result.status === 200) {
          setAccounts(result.data.accounts);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getValues]);

  const symbolOptions = React.useMemo(() => {
    return symbols.map((s) => ({
      value: s.quote ? `${s.base}-${s.quote}` : s.base,
      label: s.quote ? `${s.base}-${s.quote}` : s.base,
    }));
  }, [symbols]);

  return (
    <ChakraProvider theme={theme}>
      <Container>
        <Box mx="auto" py="10" px={{ base: "6", md: "8" }} minH="400px">
          <Heading my="10">
            Client app demo
            <Heading size="sm" color="blue">
              by Walawong
            </Heading>
          </Heading>

          <Steps activeStep={activeStep}>
            <Step title="Create a account in Dashboard">
              <StepContent>
                <Stack shouldWrapChildren spacing="4">
                  <Text>
                    Go to{" "}
                    <Link color="blue" isExternal>
                      {configs.dashboard}
                    </Link>{" "}
                    to create new account if you don't have one
                  </Text>
                  <HStack>
                    <Button size="sm" variant="ghost" isDisabled>
                      Back
                    </Button>
                    <Button size="sm" onClick={nextStep} disabled={loading}>
                      Next
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
            <Step title="Create an app">
              <StepContent>
                <Stack shouldWrapChildren spacing="4">
                  <Text>
                    In Sidebar, click to Apps to create an app. When create app
                    success, Click to app to enable [Go live].
                  </Text>
                  <Image src="./images/step2.png" />
                  <HStack>
                    <Button
                      size="sm"
                      onClick={prevStep}
                      variant="ghost"
                      disabled={loading}
                    >
                      Back
                    </Button>
                    <Button size="sm" onClick={nextStep} disabled={loading}>
                      Next
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
            <Step title="[Server] Config ">
              <StepContent>
                <Stack shouldWrapChildren spacing="4">
                  <Text>
                    Copy [App Id] and [Secret Key] in dashboard to below.
                  </Text>
                  <Box>
                    <FormControl isInvalid={!!errors.appId}>
                      <FormLabel htmlFor="appId">App id</FormLabel>
                      <Input
                        id="appId"
                        {...register("appId", {
                          required: "This is required",
                          minLength: {
                            value: 36,
                            message: "Minimum length should be 36",
                          },
                          maxLength: {
                            value: 36,
                            message: "Maximum length should be 36",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.appId && errors.appId.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.secretKey}>
                      <FormLabel htmlFor="secretKey">Secret key</FormLabel>
                      <Input
                        id="secretKey"
                        placeholder=""
                        {...register("secretKey", {
                          required: "This is required",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.secretKey && errors.secretKey.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <HStack>
                    <Button
                      size="sm"
                      onClick={prevStep}
                      variant="ghost"
                      disabled={loading}
                    >
                      Back
                    </Button>
                    <Button size="sm" onClick={nextStep} disabled={loading}>
                      Next
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
            <Step title="[Server] Create app user via api">
              <StepContent>
                <Stack shouldWrapChildren spacing="4">
                  <Text>
                    We support create a user of app base on your system user id
                  </Text>
                  <FormControl isInvalid={!!errors.clientUserId}>
                    <FormLabel htmlFor="clientUserId">
                      Your system user id
                    </FormLabel>
                    <Input
                      id="clientUserId"
                      placeholder="(optional)"
                      {...register("clientUserId", {
                        required: false,
                      })}
                    />
                    <FormErrorMessage>
                      {errors.appId && errors.appId.message}
                    </FormErrorMessage>
                  </FormControl>
                  <HStack>
                    <Button size="sm" disabled={loading} onClick={createUserId}>
                      Create app user
                    </Button>
                    <Text>or</Text>
                    <Button size="sm" disabled={loading} onClick={getAppUserId}>
                      Get app user
                    </Button>
                  </HStack>

                  <FormControl>
                    <FormLabel htmlFor="clientUserId">App user id</FormLabel>
                    <Input
                      id="appUserId"
                      disabled
                      {...register("appUserId", {
                        required: true,
                      })}
                    />
                    <FormErrorMessage>
                      {errors.appId && errors.appId.message}
                    </FormErrorMessage>
                  </FormControl>
                  <HStack>
                    <Button size="sm" variant="ghost" isDisabled>
                      Back
                    </Button>
                    <Button size="sm" onClick={nextStep}>
                      Next
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
            <Step title="[Client App] Web connect">
              <StepContent>
                <Stack shouldWrapChildren spacing="4">
                  <Text>This web connect url base your app</Text>
                  <Button size="sm" onClick={connectProvider}>
                    Connect to provider
                  </Button>
                  <HStack>
                    <Button size="sm" onClick={prevStep} variant="ghost">
                      Back
                    </Button>
                    <Button size="sm" onClick={nextStep}>
                      Next
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
          </Steps>
          <HStack
            display={activeStep === 5 ? "flex" : "none"}
            mt="10"
            spacing="4"
            shouldWrapChildren
          >
            <Text>All steps completed - please using actions </Text>
            <Button
              size="sm"
              onClick={onReset}
              variant="outline"
              verticalAlign="baseline"
            >
              Reset
            </Button>
          </HStack>
          <VStack spacing="6" mt="6" alignItems="flex-start">
            <HStack spacing="6">
              <Button
                disabled={!canGetData || loading}
                onClick={getAllAccounts}
              >
                Fetch Accounts
              </Button>
              <Box>
                <ChakraSelect
                  placeholder="Select account"
                  disabled={loading}
                  value={accountId}
                  onChange={(e) => setAccountId(e.target.value)}
                >
                  {accounts.map((account) => (
                    <option id={account.id} value={account.id}>
                      {account.provider.display_name}/{account.provider.type}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
            </HStack>
            <FormControl isInvalid={!!errors.appId}>
              <FormLabel htmlFor="appId">
                Symbol (Depends on the provider. ex: Binance: BTCUSDT, Gate:
                BTC_USDT, Coibase: BTC)
              </FormLabel>
              <Box>
                <Select
                  // @ts-ignore
                  options={symbolOptions}
                  isDisabled={!canGetData}
                  isLoading={loading}
                  value={symbol}
                  onChange={(e: any) => setSymbol(e)}
                />
              </Box>
            </FormControl>
            <HStack spacing="6">
              <Button disabled={!canGetData || loading} onClick={getBalances}>
                Get Balances
              </Button>
              <Button
                disabled={!canGetData || loading}
                onClick={getTransaction}
              >
                Get Transactions
              </Button>
            </HStack>
            <Textarea
              m="6"
              size="lg"
              value={data}
              minH="lg"
              isFullWidth={true}
              isReadOnly
            />
          </VStack>
        </Box>
      </Container>
    </ChakraProvider>
  );
};
